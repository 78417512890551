.app {
    min-height: 100vh !important;
    background: #f0f2f5 !important;
}

.ant-layout {
    background: #fff;
}

.appContent {
    height: calc(100vh - 60px);
    padding: 12px 16px 150px;
    overflow-y: scroll;
}

.appContent::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

.appContent::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    border-radius: 1em;
}

.appContent::-webkit-scrollbar-thumb {
    background-color: #d0d0d0;
    outline: none;
    border-radius: 1em;
}


@media (min-width: 768px) {
    .appContent {
        padding: 24px 24px 24px;
    }
}