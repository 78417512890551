.robotStatus {
    background: rgba(0, 0, 0, .03);
    border-color: rgba(0, 0, 0, .03);
}

.robotStatus.inPosition {
    color: #1d39c4;
}

.robotStatus.tracking {
    color: #e97a12;
}

.robotStatus.waiting {
    color: rgba(0, 0, 0, .88);
}

.robotStatus.complete {
    color: #389e0d;
}