.robotStatus {
    background: rgba(0, 0, 0, .03);
    border-color: rgba(0, 0, 0, .03);
}

.robotStatus.closing {
    color: #1d39c4;
}

.robotStatus.restoring {
    color: #e97a12;
}

.robotStatus.active {
    color: #389e0d;
}