.currentProfit {
    line-height: 1.125;
}

.currentProfitItem {
    margin-bottom: 2px;
    display: flex;
    align-items: center;
}

.currentProfitItem__lbl {
    display: block;
    min-width: 60px;
    font-size: 9px;
    color: #888;
    letter-spacing: -.2px;
}

.currentProfitItem__val {
    display: block;
    font-size: 9px;
    font-weight: 600;
}

@media (min-width: 768px) {
    .currentProfitItem {
        margin-bottom: 4px;
    }
    
    .currentProfitItem__lbl {
        margin-right: 4px;
        font-size: 12px;
        letter-spacing: -.2px;
    }
    
    .currentProfitItem__val {
        font-size: 12px;
    }
}