.userPanel {
    display: flex;
    align-items: center;
    height: 40px;
    cursor: pointer;
    padding: 0 12px;

    transition: all 0.3s;
    font-size: 0;
    color: #fff;
}

.userPanel__avatar {
    margin-right: 8px;
}

.userPanel__name {
    flex: 1;
    min-width: 0;
    font-size: 13px;
}

.user-panel-name span {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.user-pnael-dropdown {}
