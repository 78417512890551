.brokerChooser {
    position: relative;
    margin-right: 16px;
    padding: 4px 8px;
    color: #fff;
    border: 1px solid rgba(255, 255, 255, .5);
    border-radius: 8px;
}

.brokerChooserTitle {
    margin: 0 0 3px 1px;
    font-size: 8px;
    line-height: 1.125;
    font-weight: 300;
    color: rgba(255, 255, 255, .8);
}

.brokerChooserPanel {
    min-width: 90px;
    padding: 0;
    line-height: 1.125;
}

.brokerChooserPanel__name {
    font-size: 14px;
}


.statusConnected,
.statusDisconnected {
    position: absolute;
    top: 6px;
    right: 6px;
    display: flex;
    align-items: center;
}

.statusConnected:before,
.statusDisconnected:before {
    content: '';
    display: block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #ff0000;
}

.statusConnected:before {
    background: #00ff00;
}


.serverTime {
    margin: 4px 0 0 !important;
    color: rgba(255, 255, 255, 0.65);
}

.serverTime__time {
    font-size: 9px;
    line-height: 1;
    color: rgba(255, 255, 255, 0.65);
}


@media (min-width: 768px) {
    .brokerChooserTitle {
        margin-bottom: 4px;
        font-size: 10px;
    }

    .brokerChooserPanel {
        min-width: 100px;
    }

    .brokerChooserPanel__name {
        font-size: 15px;
    }

    .serverTime__time {
        font-size: 11px;
    }
}