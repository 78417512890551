@import 'antd/dist/reset.css';


.content {
  padding: 24px;
  background: #fff;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  border-top: 1px solid #e8e8e8;
}

.ant-tabs-tab {
  user-select: none;
}

.ant-form-item-explain-error {
  margin-top: 4px;
  font-size: 10px;
  line-height: 1.125;
}

.cellNumber {
  text-align: right;
}

.green {
  color: green;
}

.red {
  color: red;
}

.cellNumber i {
  user-select: none;
  opacity: 0;
}

.ant-page-header {
  margin-bottom: 15px;
  padding: 0;
}


.descSmall .ant-descriptions-item {
  padding-bottom: 0 !important;
}

.descSmall .ant-descriptions-item-label {
  font-size: 12px;
  line-height: 1.125;
}

.descSmall .ant-descriptions-item-content {
  font-size: 12px;
  line-height: 1.125;
}

.descSmall2 .ant-descriptions-item {
  padding-bottom: 0 !important;
}

.descSmall2 .ant-descriptions-item-container {
  display: block;
}

.descSmall2 .ant-descriptions-item-label {
  display: block !important;
  font-size: 12px;
  line-height: 1.125;
}

.descSmall2 .ant-descriptions-item-label:after {
  display: none;
}

.descSmall2 .ant-descriptions-item-content {
  display: block;
  font-size: 12px;
  line-height: 1.125;
}


.ant-pagination-options {
  display: none !important;
}


.ant-tag {
  padding: 0 4px;
  font-size: 10px;
  line-height: 16px;
  font-weight: 400;
}


@media (min-width: 768px) {
  .ant-tag {
    padding: 0 6px;
    font-size: 12px;
    line-height: 20px;
  }
}
