.filterValues {
    display: flex;
    flex-flow: row wrap;
    padding: 8px 0;
    color: #7a8895;
    font-size: 12px;
    line-height: 1;
}

.filterValue {
    display: flex;
    align-items: center;
    margin-right: 6px;
    padding: 4px 0;
}

.filterValue__label {
    margin-right: 5px;
}

.filterValue__values {
    display: flex;
}

.filterValue__values .ant-tag {
    border-radius: 2em;
    padding: 4px 12px;
}