.favorite {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    font-size: 20px;
    color: #7F7E85;
}

.favorite.active {
    color: #F8C302;
}