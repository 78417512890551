.instrumentValue {
    display: inline-flex;
    align-items: center;
    font-size: 12px;
    line-height: 1.125;
    font-weight: 600;
    color: #000;
}

.instrumentValue .ant-tag {
    
}

.instrumentValueTitle {
    display: block;
    margin-left: 4px;
}

@media (min-width: 768px) {
    .instrumentValue {
        max-width: 100%;
        font-size: 14px;
    }

    .instrumentValueTitle {
        margin: 0;
    }
}