.lastPrice {
    
}

.lastPrice__price {
    color: #000;
    font-weight: 600;
    white-space: nowrap;
}

.lastPrice__price span {
    display: inline-block;
    margin-left: 4px;
    font-size: .9em;
}

.lastPrice__time {
    margin-top: 2px;
    color: #888;
}
