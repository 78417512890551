.instrumentValue {
    display: block;
    max-width: 150px;
}

.instrumentValue.archive {
    opacity: .5;
}

.instrumentValue__name {
    margin-bottom: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.instrumentValue__ticker {

}

@media (min-width: 768px) {
    .instrumentValue {
        max-width: 100%;
        font-size: 14px;
    }

    .instrumentValueTitle1 {
        margin: 0;
    }
}
