.pageHeaderEmpty {
    width: 100%;
    padding-top: 8px;
}

.pageHeader {
    position: relative;
    z-index: 2;
    padding: 8px 0 20px;
    width: 100%;
}

.pageHeaderMain {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding: 0;
}

.pageHeader__start {
    position: relative;
    flex: 1;
    display: flex;
    align-items: center;
}

.pageHeader__end {
    display: flex;
    align-items: center;
}

.pageHeaderTitle {
    font-size: 18px;
    line-height: 1.125;
    font-weight: 600;
    color: #0a033c;
}

.pageHeaderInfo {
    margin-top: 8px;
    font-size: 14px;
    color: #6b7892;
}

.pageHeader .backBtn {
    margin-right: 12px;
}


@media (min-width: 1024px) {
    .pageHeader {
        padding: 0 0 20px;
    }
}