.robotPositionAmount {
    margin-top: 2px;
    font-size: 12px;
    color: #888;
}

.robotPositionTag {
    font-size: 11px;
    line-height: 1.125;
    font-weight: 600;
    color: #ccc;
}
.robotPositionTag > span {
    text-transform: uppercase;
}

.robotPositionTag.dir1 {
    color: #389e0d;
}

.robotPositionTag.dir2 {
    color: #cf1322;
}

@media (min-width: 768px) {
    .robotPositionTag {
        font-size: 12px;
    }
}