.numberValue {
    white-space: nowrap;
}

.numberValue.right {
    text-align: right;
}

.numberValue.green {
    color: green;
}

.numberValue.red {
    color: red;
}

.numberValue > span {
    display: inline-block;
    margin-left: 2px;
    font-size: .8em;
}