
.tableRowActions {
    position: absolute;
    z-index: 999;
    top: 0;
    left: 0;
    right: 0;
    height: 60px;
    background: #fff;
    border: 1px dashed #FF5545;
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 0 16px;
    
}

.tableRowActions__title {
    margin-right: 16px;
}

.tableTopRowActions__item {
    margin-right: 12px;
}

