.navigation.ant-menu {
    display: none;
}

.navigation .ant-menu-item-selected {
    color: #fff !important;
    background-color: #1890ff !important;
    border-radius: 6px !important;
}

.navigation .ant-menu-item-icon {
    display: none !important;
}

.navigation .ant-menu-title-content {
    margin: 0 !important;
}


.navigationMobile {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    height: 60px;
}

.navigationMobile:before,
.navigationMobile:after {
    display: none;
}

.navigationMobile .ant-menu-item-selected {
    color: #1890ff !important;
    background-color: transparent !important;
}

.navigationMobile .ant-menu-item {
    flex: 1;
    padding: 0;
    display: block;
    text-align: center;
    line-height: 1;
    overflow: hidden;
    padding: 0 4px;
}

.navigationMobile .ant-menu-item:after {
    display: none;
}

.navigationMobile .ant-menu-item-icon {
    font-size: 24px !important;
    margin: 0 !important;
    padding: 0 !important;
}

.navigationMobile .ant-menu-title-content {
    display: block;
    height: 12px;
    margin: 4px 0 0 !important;
    padding: 0 !important;
    font-size: 9px;
    line-height: 1.125;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media (min-width: 768px) {
    .navigation.ant-menu {
        display: flex;
        width: 100%;
    }

    .navigationMobile {
        display: none;
    }
}