.tableTop {
    display: flex;
    align-items: flex-end;
    height: 40px;
}

.tableTop__start {
    display: flex;
    align-items: flex-end;
    margin-right: 8px;
}

.tableTop__end {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    flex: 1;
}


.tableTopTitle {
    margin-right: 16px;
    padding-left: 12px;
    font-size: 16px;
    line-height: 1.125;
}

.tableTopSearch {
    max-width: 110px;
    border: 1px solid #d2d7dd !important;
    border-radius: 6px !important;
    background: #f3f2f2 !important;
}

.tableTopSearch .ant-input-prefix {
    margin-right: 6px;
}

.tableTopSearch .ant-input {
    background: #f3f2f2 !important;
}

.filterBtnTitle {
    display: none !important;
}


@media (min-width: 768px) {
    .tableTopSearch {
        max-width: 100%;
    }

    .filterBtnTitle {
        display: inline-block !important;
    }
}