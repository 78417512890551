.appHeader {
    display: flex;
    align-items: center;
    background: #001529;
    padding: 8px 16px;
    height: 64px;
}

.appHeaderStart {
    display: flex;
    align-items: center;
}

.appHeaderMiddle {
    flex: 1;
}

.appHeaderEnd {
    display: flex;
    align-items: center;
}

.app1 .ant-layout-header {
    display: flex;
    align-items: center;
    padding: 0 24px;
}


@media (min-width: 768px) {
    .appHeaderMiddle {
        flex: 1;
        display: flex;
        align-items: center;
    }
}