.filterForm.ant-form-horizontal .ant-form-item-row {
    display: block;
}

.filterForm.ant-form-horizontal .ant-form-item {
    margin: 0 8px 0 0;
}

.filterForm.ant-form-horizontal .ant-form-item-control-input {
    min-height: 28px;
}


.filterForm.ant-form-horizontal .ant-form-item-label {
    margin: 0 !important;
    padding-bottom: 0 !important;
}

.filterForm.ant-form-horizontal .ant-form-item-label > label {
    font-size: 11px !important;
    height: auto;
    line-height: 1.125
}

.filterForm.ant-form-horizontal .ant-radio-group-small .ant-radio-button-wrapper {
    font-size: 13px !important;
}

.filterForm.ant-form-horizontal .ant-radio-group-small .ant-radio-button-wrapper span i {
    display: inline-block;
    margin-left: 2px;
    font-style: normal;
    color: #888;
    line-height: 1;
}

.filterForm.ant-form-horizontal .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) span i {
    color: #fff;
}