.robotProfitStatus {
    width: 120px;
    overflow: hidden;
}

.profitChart {
    position: relative;
    height: 40px;
    display: flex;
    margin: 8px auto 16px;
}

.profitChart:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 17px;
    height: 4px;
    border-radius: 2em;
    background: rgba(0, 0, 0, .1);
}

.profitChartItem {
    position: absolute;
    top: 19px;
}

.profitChartItem:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 8px;
    border-radius: 2em;
    background: #000;
    transform: translate(-50%, -50%);
}

.profitChartItem__lbl {
    position: absolute;
    font-size: 7px;
    transform: translate(-50%, 16px);
    white-space: nowrap;
}

.profitChartItem__val {
    position: absolute;
    font-size: 8px;
    transform: translate(-50%, 8px);
    white-space: nowrap;
}

.profitChartItemStart {
    left: 8px;
}

.profitChartItemMiddle {
    left: 50%;
}

.profitChartItemEnd {
    right: 8px;
}

.profitChart.long .profitChartItemStart:before {
    background: #e81728;
}

.profitChart.long .profitChartItemEnd:before {
    background: #4abe18;
}

.profitChart.short .profitChartItemStart:before {
    background: #4abe18;
}

.profitChart.short .profitChartItemEnd:before {
    background: #e81728;
}


.profitChartCurrent {
    position: absolute;
    top: 19px;
    left: 50%;
}

.profitChartCurrent:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 11px;
    background: #d46b08;
    transform: translate(-50%, -7px);
}

.profitChartCurrent__lbl {
    position: absolute;
    font-size: 7px;
    font-weight: 600;
    transform: translate(-50%, -30px);
    white-space: nowrap;
}

.profitChartCurrent__val {
    position: absolute;
    font-size: 10px;
    font-weight: 600;
    transform: translate(-50%, -24px);
    white-space: nowrap;
}

.profitChartCurrent.green:before {
    background: #389e0d;
}

.profitChartCurrent.red:before {
    background: #cf1322;
}

.profitChartCurrent.green .profitChartCurrent__lbl,
.profitChartCurrent.green .profitChartCurrent__val {
    color: #389e0d;
}

.profitChartCurrent.red .profitChartCurrent__lbl,
.profitChartCurrent.red .profitChartCurrent__val {
    color: #cf1322;
}


.profitChartCurrentFill {
    position: absolute;
    top: 17px;
    left: 50%;
    height: 4px;
}

.profitChartCurrentFill.green {
    background: rgba(109, 220, 25, 0.5);
}

.profitChartCurrentFill.red {
    background: rgba(232, 75, 66, 0.5);
}


.robotMinMax {
    display: flex;
    justify-content: space-between;
    line-height: 1.125;
    padding: 0 4px;
}

.robotMinMaxItem {
    margin-right: 8px;
}

.robotMinMaxItem:last-child {
    margin-right: 0;
}

.robotMinMaxItem__lbl {
    display: inline-block;
    margin-right: 4px;
    font-size: 9px;
    color: #888;
}

.robotMinMaxItem__val {
    display: inline-block;
    font-size: 9px;
    font-weight: 600;
}

@media (min-width: 768px) {
    .profitChart {
        height: 40px;
        margin: 8px 0 16px;
    }

    .profitChartItem__lbl {
        font-size: 7px;
        transform: translate(-50%, 20px);
    }

    .profitChartItem__val {
        font-size: 9px;
        transform: translate(-50%, 8px);
    }

    .profitChartCurrent__val {
        font-size: 11px;
    }

    .robotMinMax {
        padding: 0;
    }

    .robotMinMaxItem__lbl {
        font-size: 9px;
    }

    .robotMinMaxItem__val {
        font-size: 9px;
    }
}