.robotValue {
    display: inline-block;
    font-size: 12px;
    line-height: 1.125;
    font-weight: 600;
    color: #000;
}

.robotValue .ant-tag {
    margin-top: 4px;
}

.robotValueTitle {
    display: block;
}

.instrumentName {
    max-width: 108px;
    margin-top: 4px;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: -.5px;
    color: #888;
}

@media (min-width: 768px) {
    .robotValue {
        font-size: 14px;
    }

    .robotValue .ant-tag {
        margin-top: 4px;
    }

    .instrumentName {
        max-width: 100%;
        margin-top: 6px;
        font-size: 12px;
        color: #888;
    }
}