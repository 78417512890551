.pageHeaderTabs .ant-tabs-nav {
    margin: 0 !important;
}

.pageHeaderTabs .ant-tabs-tab {
    padding: 8px 0;
}

.pageHeaderTabs .ant-tabs-tab+.ant-tabs-tab {
    margin: 0 0 0 16px;
}

.pageHeaderTabs .ant-tabs-tab-btn {
    font-weight: 600;
    font-size: 12px;
    letter-spacing: .1px;
    color: #666F7C;
}

.pageHeaderTabs.ant-tabs-top>.ant-tabs-nav::before,
.pageHeaderTabs.ant-tabs-bottom>.ant-tabs-nav::before,
.pageHeaderTabs.ant-tabs-top>div>.ant-tabs-nav::before,
.pageHeaderTabs.ant-tabs-bottom>div>.ant-tabs-nav::before {
    border-bottom-color: #ccc;
}

.pageHeaderTabs>.ant-tabs-nav .ant-tabs-tab-active .ant-tabs-tab-btn1,
.pageHeaderTabs>div>.ant-tabs-nav .ant-tabs-tab-active .ant-tabs-tab-btn1 {
    color: #c94141;

}

.pageHeaderTabs .ant-tabs-ink-bar1 {
    background: #c94141 !important;
}


@media (min-width: 768px) {
    .pageHeaderTabs .ant-tabs-tab {
        padding: 16px 0;
    }

    .pageHeaderTabs .ant-tabs-tab-btn {
        font-size: 14px;
        letter-spacing: .4px;
    }

    .pageHeaderTabs .ant-tabs-tab+.ant-tabs-tab {
        margin: 0 0 0 32px;
    }
}