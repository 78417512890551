.serverStatus {
    margin-right: 24px;
    line-height: 1;
    color: #fff;
}

.serverStatus .connected, .serverStatus .disconnected {
    display: flex;
    align-items: center;
}
.serverStatus .connected:before, .serverStatus .disconnected:before {
    content: '';
    display: block;
    margin-right: 8px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #00ff00;
}
.serverStatus .disconnected:before {
    background: #ff0000;
}

.serverTime {
    margin-right: 16px;
    line-height: 1;
    color: #fff;
}

.serverTime__title {
    margin-bottom: 4px;
    font-size: 14px;
}

.serverTime__datetime {
    font-size: 16px;
}